import {
    createSSRApp
} from "vue";

import uviewPlus from 'uview-plus'
import * as Pinia from 'pinia';
import request from '@/requestConfig.js'


import App from "./App.vue";
export function createApp() {
    const app = createSSRApp(App);
    app.use(Pinia.createPinia());
    app.use(uviewPlus)
    request.initRequest(app)
    return {
        app,
        Pinia // 此处必须将 Pinia 返回
    };
}