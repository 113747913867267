<script>
    import {
        useStore
    } from './stores/store.js'
    import util from './util.js'
    export default {
        onLaunch: async function() {
            console.log('App Launch')
            const store = useStore()
            // 如果有此数据则跳转
        },
        onShow: async function() {
            console.log('App Show')
            const store = useStore()
        },
        onHide: function() {
            console.log('App Hide')
        },
    }
</script>

<style lang="scss">
    @import "uview-plus/index.scss";

    * {
        box-sizing: border-box;
    }
</style>