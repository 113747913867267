import util from '@/util.js'
import {
    useStore
} from '@/stores/store.js'

const requestInterceptors = (vm) => {
    /**
     * 请求拦截
     * @param {Object} http
     */
    uni.$u.http.interceptors.request.use((config) => { // 可使用async await 做异步操作
            debugger
            // 初始化请求拦截器时，会执行此方法，此时data为undefined，赋予默认{}
            config.data = config.data || {}
            // 可以在此通过vm引用vuex中的变量，具体值在vm.$store.state中
            // console.log(vm.$store.state);
            return config
        }, (config) => // 可使用async await 做异步操作
        Promise.reject(config))
}

const responseInterceptors = (vm) => {
    /**
     * 响应拦截
     * @param {Object} http 
     */
    uni.$u.http.interceptors.response.use((response) => {
        /* 对响应成功做点什么 可使用async await 做异步操作*/
        console.log('responseInterceptors', response)
        const data = response.data
        // 自定义参数
        const custom = response.config?.custom
        /*
		if (data.code !== 200) { // 服务端返回的状态码不等于200，则reject()
			// 如果没有显式定义custom的toast参数为false的话，默认对报错进行toast弹出提示
			if (custom.toast !== false) {
				uni.$u.toast(data.message)
			}
			// 如果需要catch返回，则进行reject
			if (custom?.catch) {
				return Promise.reject(data)
			} else {
				// 否则返回一个pending中的promise
				return new Promise(() => { })
			}
		}
        return data.data || {}
        */
        return response
    }, (response) => {
        /*  对响应错误做点什么 （statusCode !== 200）*/
        // uni.$u.toast('网络出问题了，请重试' + response.data.message)
        if (response.statusCode == 401 && response.config.url !== '/api/me') {
            const store = useStore()
            store.login()
        }
        return Promise.reject(response)
    })
}

//  初始化请求配置
const initRequest = (vm) => {
    uni.$u.http.setConfig((defaultConfig) => {
        /* defaultConfig 为默认全局配置*/
        // #ifndef H5
        defaultConfig.baseURL = `https://gym-store.ifire.mobi`; /* 根域名 */
        // defaultConfig.baseURL = `http://localhost:3000`; /* 测试 根域名 */
        // defaultConfig.baseURL = `http://192.168.0.107:3000`; /* 测试 根域名 */
        // #endif
        return defaultConfig
    })
    // requestInterceptors()
    responseInterceptors()
}
export default {
    initRequest
}