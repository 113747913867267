import {
    defineStore
} from 'pinia';
import wx from 'weixin-js-sdk'
import util from '@/util.js'

let lifeData = {};

try {
    // 尝试获取本地是否存在lifeData变量，第一次启动APP时是不存在的
    lifeData = uni.getStorageSync('lifeData');
} catch (e) {

}

const saveLifeData = (key, value) => {
    let tmp = uni.getStorageSync('lifeData');
    // 第一次打开APP，不存在lifeData变量，故放一个{}空对象
    tmp = tmp ? tmp : {};
    tmp[key] = value;
    // 执行这一步后，所有需要存储的变量，都挂载在本地的lifeData对象中
    uni.setStorageSync('lifeData', tmp);
}

export const useStore = defineStore('store', {
    state: () => {
        return {
            user: lifeData.user ? lifeData.user : '',
            defaultGym: lifeData.defaultGym ? lifeData.defaultGym : ''
        };
    },
    // 也可以这样定义
    // state: () => ({ count: 0 })
    actions: {
        setDefaultGym(gym) {
            this.defaultGym = gym
            saveLifeData('defaultGym', gym)
        },

        async login() {
            // 去登陆
            uni.navigateTo({
                url: 'login'
            })
        },

        async checkLogin() {
            try {
                const res = await uni.$u.http.get('/api/me')
                saveLifeData('member', res.data)
            } catch (e) {
                console.error('member', e)
                this.login()
            }
        },
        async loadMe() {

        }

    },
});